import React from "react";
import TopNavigation from "../../Components/TopNavigation";
import { useParams } from "react-router-dom";
import { Button, Divider, Flex, Layout, Typography } from "antd";
import Announcement from "../../Components/Announcement";
import Copyright from "../../Components/Copyright";
import SocialMedia from "../../Components/SocialMedia";
import Chip from "../../Components/Chip";
import Avatar from "../../Components/Avatar";

const { Title, Text } = Typography;

const Exam = () => {
  const { id } = useParams();
  return (
    <>
      <TopNavigation />
      <Layout>
        <Flex
          vertical
          style={{ padding: window.innerWidth <= 768 ? 32 : "32px 20%" }}
        >
          <Title level={4}>How Indian You Are?</Title>
          <Text>
            Let's check your knowledge about Great India? In this quiz, We have
            questions about India which will make feel about Indian.{" "}
          </Text>
          <Flex gap={16} style={{ marginTop: 16 }} wrap="wrap">
            <Chip>+4/-1 Marking Scheme</Chip>
            <Chip>20 Quesitons</Chip>
            <Chip>20 Minutes</Chip>
          </Flex>
          <Flex gap={16}>
            <Button
              type="primary"
              style={{
                marginTop: 16,
                width: 100,
                height: 32,
                borderRadius: 16,
              }}
            >
              Try Now
            </Button>
            <Button
              style={{
                marginTop: 16,
                width: 200,
                height: 32,
                borderRadius: 16,
              }}
            >
              Share with Your Friends
            </Button>
          </Flex>
          <Divider orientation="left">Leaderboard Stars</Divider>
          <Flex gap={16} wrap="wrap">
            {Array(5)
              .fill(0)
              .map((it) => {
                return (
                  <Flex align="center" vertical>
                    <Avatar>Nitish</Avatar>
                    <Title level={5} style={{ marginBottom: 0 }}>
                      Nitish Kumar
                    </Title>
                  </Flex>
                );
              })}
          </Flex>
          <Divider orientation="left">Quiz Creator</Divider>
          <Flex>
            <Avatar>Nitish</Avatar>
            <Flex vertical style={{ margin: "0px 16px" }}>
              <Title level={5} style={{ marginBottom: 0 }}>
                Nitish Kumar
              </Title>
              <Text>2 hours ago</Text>
            </Flex>
          </Flex>
        </Flex>
      </Layout>
      <Announcement />
      <Copyright />
      <SocialMedia />
    </>
  );
};

export default Exam;
