import React from "react";

const Chip = ({ children }) => {
  return (
    <div
      style={{
        borderRadius: 20,
        backgroundColor: "lightgray",
        padding: "8px 16px",
      }}
    >
      {children}
    </div>
  );
};

export default Chip;
