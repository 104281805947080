import React from "react";
import { Row, Col, Typography, Flex, Carousel } from "antd";
import "antd/dist/reset.css";
import { getImageFromAssetFolder } from "../../../utils/assets";

const data = [
  {
    title: "Quality Quizzes and Test Series",
    description:
      "Enhance your learning with our comprehensive Quality Quizzes and Test Series. Designed to challenge your understanding and solidify concepts, our quizzes offer detailed feedback and performance insights, helping you track your progress and prepare effectively for exams. Dive into a variety of subjects and topics, tailored to boost your confidence and knowledge retention.",
    imgSrc: getImageFromAssetFolder("quiz_icon.png"),
    backgroundColor: "#440077",
  },
  {
    title: "Universal Syllabus Tracker",
    description:
      "Stay organized and on top of your studies with our Universal Syllabus Tracker. Easily manage and monitor your coursework across multiple subjects, ensuring you never miss a deadline or important topic. With real-time updates and progress tracking, you can plan your study sessions efficiently and achieve your academic goals with confidence.",
    imgSrc: getImageFromAssetFolder("syllabus_tracker.png"),
    backgroundColor: "#007845",
  },
  {
    title: "Performance Trends & Analytics",
    description:
      "Unlock deeper insights into your learning journey with Performance Trends & Analytics. Track your progress over time, identify strengths and areas for improvement, and make data-driven decisions to enhance your study strategy. Our detailed analytics help you understand your performance patterns, empowering you to focus your efforts where they matter most.",
    imgSrc: getImageFromAssetFolder("trends_and_analytics.png"),
    backgroundColor: "#880044",
  },
  {
    title: "Detailed Quiz & Test Analysis",
    description:
      "Get a thorough breakdown of every quiz and test with our Detailed Quiz & Test Analysis. Understand your performance at a granular level, with insights into correct and incorrect answers, time spent on each question, and topic-specific strengths and weaknesses. Our analysis helps you fine-tune your preparation and achieve better results.",
    imgSrc: getImageFromAssetFolder("analysis.png"),
    backgroundColor: "#143759",
  },
];

const { Title, Text } = Typography;

const TopSlideShow = () => {
  return (
    <Carousel autoplay style={{ height: "100%" }}>
      {data.map((item, index) => (
        <div key={index} style={{ height: "100%" }}>
          <AlternateSection
            title={item.title}
            description={item.description}
            imgSrc={item.imgSrc}
            backgroundColor={item.backgroundColor}
            reverse={index % 2 !== 0}
          />
        </div>
      ))}
    </Carousel>
  );
};

const AlternateSection = ({
  title,
  description,
  imgSrc,
  reverse,
  backgroundColor,
}) => {
  return (
    <Row
      align="middle"
      style={{
        backgroundColor: backgroundColor,
        padding: 48,
        height: window.innerWidth <= 768 ? 540 : 360,
      }}
    >
      <Col xs={24} sm={12} md={reverse ? { order: 2 } : {}}>
        <Flex justify="center" align="center" style={{ padding: 24 }}>
          <img
            style={{ width: "50%", maxWidth: 200 }}
            alt={title}
            src={imgSrc}
          />
        </Flex>
      </Col>
      <Col xs={24} sm={12}>
        <Flex vertical align="center" style={{ color: "white" }}>
          <Title
            level={4}
            style={{ margin: 0, color: "white", textAlign: "center" }}
          >
            {title}
          </Title>
          <Text
            style={{
              margin: 0,
              color: "white",
              textAlign: "center",
              maxWidth: 400,
            }}
          >
            {description}
          </Text>
        </Flex>
      </Col>
    </Row>
  );
};

export default TopSlideShow;
