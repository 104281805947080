import React from "react";
import { getImageFromAssetFolder } from "../utils/assets";
import { Flex, Image, Typography } from "antd";
const { Text } = Typography;

const appUrl = process.env.REACT_APP_ANDROID_APP_URL;

const Announcement = () => {
  return (
    <Flex
      style={{
        backgroundColor: "#32a852",
        color: "white",
        padding: "16px 16px",
      }}
      justify="center"
      align="center"
    >
      <Image
        style={{ height: 24, width: 30 }}
        preview={false}
        src={getImageFromAssetFolder("ic_announcement.png")}
      />
      <Text style={{ color: "#fff", fontSize: 16, margin: 8 }}>
        Our Android app is now live with early access! Be among the first to try
        it out.{" "}
        <a style={{ color: "#fff", textDecoration: "underline" }} href={appUrl}>
          Download Now
        </a>
      </Text>
    </Flex>
  );
};

export default Announcement;
