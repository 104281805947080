import React from "react";
import { colors } from "../style/colors";

const Avatar = ({ children, size = 40, textColor = "white" }) => {
  return (
    <div
      style={{
        height: size,
        width: size,
        background: colors.primary,
        borderRadius: size / 2,
        textAlign: "center",
        padding: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 800,
        fontSize: size / 2,
        color: textColor,
      }}
    >
      {children.charAt(0).toUpperCase()}
    </div>
  );
};

export default Avatar;
