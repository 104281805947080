import React from "react";
import { Flex, Image, Typography } from "antd";
import { getImageFromAssetFolder } from "../utils/assets";
const { Title, Text } = Typography;

const Branding = () => {
  return (
    <Flex gap={16}>
      <Image
        preview={false}
        width={40}
        src={getImageFromAssetFolder("short_logo.png")}
      />
      <Flex vertical>
        <Image
          preview={false}
          width={80}
          src={getImageFromAssetFolder("logo_text.png")}
        />
        <Text style={{ fontSize: 12 }}>Learn Beyond The Obvious</Text>
      </Flex>
    </Flex>
  );
};

export default Branding;
