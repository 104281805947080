import React from "react";
import { useLocation } from "react-router-dom";
import Routing from "./Routing/Routing";
import { ConfigProvider } from "antd";

const App = () => {
  const location = useLocation();
  const originalUrl =
    window.location.origin +
    location.pathname +
    location.search +
    location.hash;

  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#2196f3ff" } }}>
      <Routing isLoggedIn={false} originalUrl={originalUrl} />
    </ConfigProvider>
  );
};

export default App;
