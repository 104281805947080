import React from "react";

const GradientText = ({
  children,
  fontSize = "3rem",
  fontWeight = "bold",
  colorStart = "#457812",
  colorEnd = "#111896",
}) => {
  const gradientStyle = {
    background: `linear-gradient(to right, ${colorStart} , ${colorEnd})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: fontSize,
    fontWeight: fontWeight,
    textAlign: "center",
  };

  return <div style={gradientStyle}>{children}</div>;
};
export default GradientText;
