import React from "react";
import { Layout, Typography } from "antd";

const { Text } = Typography;

const Copyright = () => {
  return (
    <Layout style={{ backgroundColor: "#000" }}>
      <Text style={{ textAlign: "center", padding: 16, color: "#fff" }}>
        Copyright © 2012 - 2024 ZeroEqualOne: Learn Beyond The Obvious. All
        rights reserved.
      </Text>
    </Layout>
  );
};

export default Copyright;
