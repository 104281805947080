import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Exam from "../Pages/Exam/Exam";

const Routing = ({ isLoggedIn, originalUrl }) => {
  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route exact path="/" element={<Home originalUrl={originalUrl} />} />
        </>
      ) : (
        <>
          <Route path="/exam/:id" element={<Exam />} />
          <Route path="*" element={<Home originalUrl={originalUrl} />} />
        </>
      )}
    </Routes>
  );
};

export default Routing;
