import React from "react";
import {
  TwitterOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import { openSocialMedia, SM } from "../utils/socialMedia";
import { Flex } from "antd";

const SocialMedia = () => {
  return (
    <Flex
      justify="center"
      align="center"
      gap={16}
      style={{ backgroundColor: "#000", paddingTop: 8, paddingBottom: 16 }}
    >
      <FacebookOutlined
        style={{ fontSize: 24, color: "#fff" }}
        onClick={() => openSocialMedia(SM.FB)}
      />
      <LinkedinOutlined
        style={{ fontSize: 24, color: "#fff" }}
        onClick={() => openSocialMedia(SM.IN)}
      />
      <InstagramOutlined
        style={{ fontSize: 24, color: "#fff" }}
        onClick={() => openSocialMedia(SM.INSTA)}
      />
      <TwitterOutlined
        style={{ fontSize: 24, color: "#fff" }}
        onClick={() => openSocialMedia(SM.X)}
      />
      <YoutubeOutlined
        style={{ fontSize: 24, color: "#fff" }}
        onClick={() => openSocialMedia(SM.YT)}
      />
    </Flex>
  );
};

export default SocialMedia;
