import React, { useState } from "react";
import {
  Flex,
  Modal,
  Steps,
  Button,
  Layout,
  Typography,
  Row,
  Col,
  Card,
  Image,
} from "antd";
import TopSlideShow from "./components/TopSlideShow";
import TopNavigation from "../../Components/TopNavigation";
import { colors } from "../../style/colors";
import GradientText from "../../Components/GradientText";
import { getImageFromAssetFolder } from "../../utils/assets";

import Copyright from "../../Components/Copyright";
import SocialMedia from "../../Components/SocialMedia";
import Announcement from "../../Components/Announcement";

const { Text, Title } = Typography;
const { Content } = Layout;

const appUrl = process.env.REACT_APP_ANDROID_APP_URL;

const stepsForDeepLink = [
  {
    title: "Having App URL",
    description: "Having a valid app URL",
  },
  {
    title: "Download App",
    description: "Download Android App",
  },
  {
    title: "Try Same URL",
    description: "Open same URL from here only",
  },
  {
    title: "Choose ZeroEqualOne App",
    description: "Choose ZeroEqualOne Android App while opening link",
  },
  {
    title: "Bumm, You are done!",
    description: "Please don't forget to share your feedback",
  },
];

const features = [
  {
    icon: getImageFromAssetFolder("ic_feature_tracker.png"),
    title: "Syllabus Tracking",
    text: "Tracking your syllabus helps you stay on track and ensure consistent progress.",
  },
  {
    icon: getImageFromAssetFolder("ic_feature_exam.png"),
    title: "Quality Quizzes",
    text: "Attempting a variety of high-quality quizzes helps you gauge your preparation level effectively.",
  },
  {
    icon: getImageFromAssetFolder("ic_feature_trends.png"),
    title: "Trends & Analytics",
    text: "Analyzing trends and metrics, such as percentage scores and time taken per question, helps you identify areas for improvement.",
  },
  {
    icon: getImageFromAssetFolder("ic_feature_report.png"),
    title: "Detailed Quiz Analysis",
    text: "A thorough analysis of quiz results helps you pinpoint concepts and topics that need more focus and attention.",
  },
];

const Home = ({ originalUrl }) => {
  const [openDeepLinkDialog, setOpenDeepLinkDialog] = useState(
    originalUrl != window.location.origin + "/"
  );

  return (
    <>
      <TopNavigation />
      <TopSlideShow />
      <Flex vertical style={{ padding: "32px 0px" }}>
        <Flex vertical align="center" justify="center" style={{ padding: 32 }}>
          <GradientText fontSize="2rem" colorStart="#fcba03" colorEnd="#fc039d">
            Ultimate Companion For Students
          </GradientText>
          <Text
            style={{
              margin: "0px 16px",
              textAlign: "center",
              fontSize: 22,
              color: colors.textHeading,
              fontFamily: '"Cherry Swash", "serif"',
            }}
          >
            Not just an exam, but a complete career path—making career success
            predictable, measurable, and expertly guided every step of the way.
          </Text>
        </Flex>
        <Content style={{ padding: "0 32px", overflow: "hidden" }}>
          <Row gutter={[16, 16]} style={{ margin: "16px 0px" }}>
            {features.map((feature) => {
              return (
                <Col lg={6}>
                  <Card footer={null}>
                    <Flex>
                      <Image
                        preview={false}
                        src={feature.icon}
                        style={{ height: 60, width: 60, marginRight: 16 }}
                      />
                      <Flex vertical>
                        <Title level={4} style={{ color: colors.textHeading }}>
                          {feature.title}
                        </Title>
                        <Text style={{ color: colors.textSubHeading }}>
                          {feature.text}
                        </Text>
                      </Flex>
                    </Flex>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Content>
      </Flex>
      <Announcement />
      <Copyright />
      <SocialMedia />
      {/* Modals */}
      <Modal
        open={openDeepLinkDialog}
        onCancel={() => setOpenDeepLinkDialog(false)}
        footer={null}
      >
        <Flex
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: 32,
          }}
          vertical
          gap={24}
        >
          <Layout
            style={{
              padding: "4px 16px",
              borderRadius: 4,
            }}
          >
            <a href={originalUrl}>{originalUrl}</a>
          </Layout>
          <div style={{ maxWidth: 400 }}>
            <Steps current={1} direction="vertical" items={stepsForDeepLink} />
          </div>
          <Button
            onClick={() => window.open(appUrl, "_blank")}
            type="primary"
            style={{ width: 160 }}
          >
            Download App
          </Button>
          <Button
            onClick={() => window.open(originalUrl, "_blank")}
            type="primary"
            style={{ width: 160 }}
          >
            Retry Link
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default Home;
