export const SM = {
  FB: "facebook",
  INSTA: "instagram",
  IN: "linkedin",
  X: "twitter",
  YT: "youtube",
};

export const openSocialMedia = (sm) => {
  switch (sm) {
    case SM.FB:
      window.open(
        "https://www.facebook.com/profile.php?id=61562764201260",
        "_blank"
      );
      break;
    case SM.INSTA:
      window.open("https://www.instagram.com/zeroequaloneapp/", "_blank");
      break;
    case SM.IN:
      window.open("https://www.linkedin.com/company/zeroequalone", "_blank");
      break;
    case SM.X:
      window.open("https://x.com/zeroequalone", "_blank");
      break;
    case SM.YT:
      window.open("https://www.youtube.com/@zeroequalone", "_blank");
      break;
  }
};
