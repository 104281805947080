import React from "react";
import { Flex, Tooltip, Image, Modal, Steps, Button, Layout } from "antd";
import { getImageFromAssetFolder } from "../utils/assets";
import Branding from "./Branding";

const appUrl = process.env.REACT_APP_ANDROID_APP_URL;

const TopNavigation = () => {
  return (
    <Flex
      style={{
        zIndex: 1000,
        alignItems: "center",
        position: "sticky",
        justifyContent: "space-between",
        top: 0,
        padding: "16px 24px",
        backgroundColor: "#ffffff",
      }}
    >
      <Branding />
      <Tooltip title="Download App">
        <Image
          onClick={() => window.open(appUrl, "_blank")}
          preview={false}
          style={{ borderRadius: 8 }}
          height={36}
          src={getImageFromAssetFolder("get_it_on_play_store.png")}
        />
      </Tooltip>
    </Flex>
  );
};

export default TopNavigation;
